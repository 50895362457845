// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  CONTENT_API, ENVIRONMENT_NAME, SEARCH_API, SHIPPING_RATE_API, SHOP_ORIGIN, VITALS_LOGGING_API,
} from '@/services/Configuration.js'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { debug, error, log } from '@/services/Log'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = ENVIRONMENT_NAME || process.env.ENVIRONMENT_NAME || 'development'

const toOrigin = (maybeDomain: string) => (
  `http${String(maybeDomain).includes('localhost') ? '' : 's'}://${
    String(maybeDomain)
      .replace(/https?:\/\//, '')
      .replace(/^\/$/, '')
      .replace(/^\.$/, '')
  }`
)

const toTarget = (origin: string) => {
  try {
    const url = toOrigin(origin)
    const target = `^${url}([a-zA-Z0-9-._~:/?#\\[\\]@!$&'()*+,;=]*)?$`
    debug(`'Adding to Allowed Sentry Target: ${target}`, { url, origin, target })
    return new RegExp(target)
  } catch (err) {
    error(`Failed to create Sentry target for ${origin} with ${String(err)}`, err)
  }
  return null
}

const domainsWeAllowTracingFrom = [
  SHOP_ORIGIN,
  CONTENT_API,
  SEARCH_API,
  VITALS_LOGGING_API,
  SHIPPING_RATE_API,
]

const isNotNull = <T>(value: T | null): value is T => value !== null

const getTracePropagationTargets = () => domainsWeAllowTracingFrom.map(toTarget).filter(isNotNull)
const getTracingOrigins = () => domainsWeAllowTracingFrom.map(toOrigin)

allPromisesWithRetries(() => [
  import('@sentry/nextjs'),
  import('@sentry/integrations'),
  import('@sentry/tracing'),
]).then(([
  { init },
  { ExtraErrorData },
  { BrowserTracing },
]) => {
  init({
    dsn: SENTRY_DSN || 'https://636b9931b0164c9f99f8d182cb8badaa@o32694.ingest.sentry.io/6611343',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,
    environment: SENTRY_ENVIRONMENT,
    normalizeDepth: 11,
    integrations: [
      new ExtraErrorData({ depth: 10 }),
      new BrowserTracing({
        traceFetch: false,
        traceXHR: false,
        tracingOrigins: getTracingOrigins(),
        shouldCreateSpanForRequest: () => false,
      }),
    ],
    tracePropagationTargets: getTracePropagationTargets(),
    tracesSampler: () => 0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [/^Product not found for sku: ([\w]*)$/],
  })
  log('Sentry loaded')
}).catch((e) => {
  error('Failed to load Sentry', e)
})
