import {
  getCLS, getFID, getLCP, Metric,
} from 'web-vitals'
import { VITALS_LOGGING_API } from '@/services/Configuration'

const sendToAnalytics = (metric: Metric) => {
  try {
    const { pathname } = window.location
    const body = JSON.stringify({
      MetricName: metric.name,
      Value: metric.value,
      pathname,
    })
    fetch(`${VITALS_LOGGING_API}/analytics/`, { body, method: 'POST', keepalive: true }).catch(() => {
      // fail silently / don't interrupt
    })
  } catch {
    // fail silently / don't interrupt
  }
}

export const logVitals = () => {
  [getLCP, getCLS, getFID].forEach((functor) => {
    try {
      functor(sendToAnalytics)
    } catch {
      // fail silently / don't interrupt
    }
  })
}

export default logVitals
