import { SHOP_ORIGIN } from '@/services/Configuration'
import { useEffect, useState } from 'react'

export const useIsClientOnTheWrongDomain = (): boolean => {
  const [isOnWrongDomain, setIsOnWrongDomain] = useState(false)
  useEffect(() => {
    if (typeof window !== 'object' || window === null) {
      return
    }
    const { origin } = window.location
    if (origin !== SHOP_ORIGIN) {
      setIsOnWrongDomain(true)
    }
  }, [])
  return isOnWrongDomain
}

export const useRedirectIfNotOnShopDomain = (): void => {
  const isOnWrongDomain = useIsClientOnTheWrongDomain()
  useEffect(() => {
    if (isOnWrongDomain) {
      window.location.href = SHOP_ORIGIN + window.location.pathname + window.location.search
    }
  }, [isOnWrongDomain])
}
