/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Cookies from 'js-cookie'
import { FEATURE_TOGGLES, type FeatureToggle } from '@/services/Configuration'
import { assertion, array, string } from '@recoiljs/refine'
import type { AppContext } from 'next/app'

type Query = Record<string, string | string[]>
const querySpy: Record<string, Query> = {}
const cookieSpy: Record<string, Record<string, string | undefined>> = {}

type RequiredNextContext = {
  ctx: AppContext['ctx']
}

const getRequestId = (appContext: RequiredNextContext) => (
  `${appContext.ctx.req?.url || ''}|${appContext.ctx.req?.headers.cookie || ''}`
)

const assertStringArray = assertion(array(string()), 'Feature enabled string array failed assertion')
const guaranteeStringArray = (value: unknown): readonly string[] => {
  try {
    return assertStringArray(value)
  } catch (err) {
    return []
  }
}

export const pushRequestId = (appContext: RequiredNextContext) => {
  try {
    global.requestId = [getRequestId(appContext), ...guaranteeStringArray(global.requestId)]
  } catch (err) {
    console.error(`Failed to pushRequestId: ${String(err)}`)
  }
}
export const popRequestId = () => {
  try {
    global.requestId = guaranteeStringArray(global.requestId)?.slice(1) || []
  } catch (err) {
    console.error(`Failed to popRequestId: ${String(err)}`)
  }
}

const getQuerySpyInstance = () => (
  querySpy[String(guaranteeStringArray(global.requestId)?.[0]) || '-1']
)

const setQuerySpyInstance = (query: Query) => {
  querySpy[String(guaranteeStringArray(global.requestId)?.[0]) || '-2'] = query
}

const getCookieSpyInstance = () => (
  cookieSpy[String(guaranteeStringArray(global.requestId)?.[0]) || '-1']
)

const setCookieSpyInstance = (cookies: Record<string, string | undefined>) => {
  cookieSpy[String(guaranteeStringArray(global.requestId)?.[0]) || '-2'] = cookies
}

export const appSSRFeatureTogglesSpy = (appContext: RequiredNextContext) => {
  if (typeof window === 'undefined') {
    try {
      // The next line enable us to use queryParams as feature flags Server Side
      setQuerySpyInstance(
        Object
          .entries(appContext.ctx.query).reduce<Query>((query, [key, value]) => {
          if (typeof value === 'undefined') return query
          return {
            ...query,
            [key]: value,
          }
        }, {}),
      )
      // global.cookies = appContext.ctx.req?.headers?.cookie
      setCookieSpyInstance(
        (
          appContext.ctx?.req?.headers.cookie
        || ''
        ).split(';').map((cookie) => [
          String(cookie.trim().split('=')[0]).trim(),
          String(cookie.trim().split('=')[1]).trim(),
        ]).reduce((cookies, [key, value]) => ({
          ...cookies,
          [key]: value,
        }), {}),
      )
    } catch (err) {
      console.error(`Failed to use appSSRFeatureTogglesSpy SSR query: ${String(err)}`)
    }
  }
}

type FeatureCheck = (featureFlag: FeatureToggle) => boolean
const checkCookieClientSide: FeatureCheck = (featureFlag) => {
  try {
    return Cookies.get(featureFlag) === 'true'
  } catch (err) {
    return false
  }
}

const checkCookieServerSide: FeatureCheck = (featureFlag) => (
  getCookieSpyInstance()?.[featureFlag] === 'true'
)
const setByCookie: FeatureCheck = (featureFlag) => {
  try {
    if (typeof window === 'undefined') {
      return checkCookieServerSide(featureFlag)
    }
    return checkCookieClientSide(featureFlag)
  } catch (err) {
    return false
  }
}

const checkForQueryParamCientSide: FeatureCheck = (featureFlag) => {
  try {
    return (
      // Check for server side before accessing window
      typeof window !== 'undefined'
      && new URLSearchParams(window.location.search).get(featureFlag) === 'true'
    )
  } catch (err) {
    return false
  }
}

const checkForQueryParamServerSide: FeatureCheck = (featureFlag) => {
  try {
    return (
      typeof global !== 'undefined'
      && getQuerySpyInstance()?.[featureFlag] === 'true'
    )
  } catch (err) {
    console.error(`Failed to check SSR query: ${String(err)}`)
    return false
  }
}
const setByQueryParam: FeatureCheck = (featureFlag) => {
  try {
    if (typeof window === 'undefined') {
      return checkForQueryParamServerSide(featureFlag)
    }
    return checkForQueryParamCientSide(featureFlag)
  } catch (err) {
    return false
  }
}

const setByFeatureToggle = (featureFlag: FeatureToggle): boolean => !!FEATURE_TOGGLES[featureFlag]

export const isFeatureEnabled = (featureFlag: FeatureToggle): boolean => (
  setByFeatureToggle(featureFlag)
    || setByQueryParam(featureFlag)
    || setByCookie(featureFlag)
)

export default isFeatureEnabled
