/* eslint-disable no-console */
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import { v4 as uuidv4 } from 'uuid'

const saveUUIDToLocalStorage = (userID: string) => {
  try {
    if (typeof window === 'undefined') return
    localStorage.setItem('CSC_LOGS_UUID', userID)
  } catch (e) {
    console.error('Failed to save UUID to local storage', e)
  }
}
const getUUIDFromLocalStorage = (): string | null => {
  try {
    if (typeof window === 'undefined') return null
    return localStorage.getItem('CSC_LOGS_UUID')
  } catch (e) {
    console.error('Failed to get UUID from local storage', e)
    return null
  }
}
const createNewUUID = async (): Promise<string> => {
  const userID: string = uuidv4()
  saveUUIDToLocalStorage(userID)
  return userID
}

const getLoggingId = async (): Promise<string> => (
  getUUIDFromLocalStorage() || await createNewUUID()
)

export type LogOperation = 'debug' | 'log' | 'error' | 'warn' // keyof (typeof console)

const maybeJSONParsedArgs = (args: unknown[]): string => {
  try {
    return JSON.stringify(args)
  } catch (e) {
    return `JSON.parse_FAILED: ${String(args)}`
  }
}

const tryUploadLogToDMP = (level: LogOperation) => async (message: string, ...args: unknown[]) => {
  try {
    if (!isFeatureEnabled('isDMPLoggingEnabled')) return
    if (typeof window === 'undefined') return
    const [
      { DMP },
      { getGuestUserCredentials },
      userID,
    ] = await Promise.all([
      import('@csc/csc-sdk'),
      import('services/FramedCheckout/cscPaymentsServices/getGuestUserCredentials'),
      getLoggingId(),
    ])
    const timestampInMilliseconds = Date.now()
    const randomNumber = Math.floor(Math.random() * 1000000)
    const messageId = `${userID}-${timestampInMilliseconds}-${randomNumber}`
    const credentials = await getGuestUserCredentials()
    await DMP({
      credentials,
    }).putData({
      dataSetName: 'sf_ssr_client_logs',
      data: {
        messageId,
        level,
        message,
        userID,
        args: maybeJSONParsedArgs(args),
      },
    })
  } catch (e) {
    console.error(`tryUploadLogToDMP Failed to upload log to DMP ${String(e)}`, e)
  }
}

const checkIfLoggingIsEnabled = () => isFeatureEnabled('isLoggingEnabled') || typeof window === 'undefined'
const baseLog = (level: LogOperation, ...args: unknown[]) => {
  try {
    tryUploadLogToDMP(level)(String(args?.[0] || ''), ...(args || []).slice(1))
      .catch((e) => console.error('baseLog Failed to upload log to DMP', e))
  } catch (e) {
    console.error('baseLog Failed to upload log to DMP', e)
  }
  const isLoggingEnabled = checkIfLoggingIsEnabled()
  if (!isLoggingEnabled) return
  if (isFeatureEnabled('isLogTracingEnabled')) {
    console.trace()
  }
  if (typeof console[level] === 'function') {
    console[level]?.(Date.now(), new Date(), ...args)
  }
}

export const debug = (...args: unknown[]) => baseLog('debug', ...args)

export const log = (...args: unknown[]) => baseLog('log', ...args)

export const error = (...args: unknown[]) => baseLog('error', ...args)

export const warn = (...args: unknown[]) => baseLog('warn', ...args)
